import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { LANGUAGE_CODE_EN, SHORT_DATE_FORMAT } from '@core/constants/defaults';

export const IBAN_PATTERN = new RegExp('^[a-zA-Z]{2}\\d{2}\\w{0,30}$');
export const DESCRIPTION_MAX_LENGTH = 140;
export const specialCharactersPattern = /Ä|Ö|Ü|ß|ä|ö|ü|&|\*|%|\!|=|#|;|§/g;
export const replacements: { [key: string]: string } = {
  Ä: 'Ae',
  Ö: 'Oe',
  Ü: 'Ue',
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
  '&': '+',
};
export const hasBenFeeCountryCodes = [
  'be',
  'bg',
  'dk',
  'de',
  'ee',
  'fi',
  'fr',
  'gr',
  'ie',
  'it',
  'hr',
  'lv',
  'lt',
  'lu',
  'mt',
  'nl',
  'at',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'es',
  'se',
  'cz',
  'hu',
  'gb',
  'cy',
  'is',
  'li',
  'no',
];
export const DATE_FORMATS = {
  parse: {
    dateInput: ['DD.MM.YYYY', 'DD/MM/YYYY'],
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export function replacer(match: string) {
  return replacements[match] ?? '';
}

export function setDateEmptyIfItIsNull(control: AbstractControl | null) {
  if (control && !control.value) control.setValue(undefined);
}

function clearAndUpdateToFormControl(control: AbstractControl | null) {
  if (control) {
    control.clearValidators();
    control.updateValueAndValidity();
  }
}

export function clearFormControls(controls: AbstractControl[]) {
  controls.forEach((control: AbstractControl) => {
    clearAndUpdateToFormControl(control);
  });
}

function setAndUpdateToFormControl(control: AbstractControl | null) {
  if (control) {
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  }
}

export function setAndUpdateControls(controls: AbstractControl[]) {
  controls.forEach((control: AbstractControl) => {
    setAndUpdateToFormControl(control);
  });
}

export function markComponentAsTouched(
  component: AbstractControl | UntypedFormGroup
) {
  component.markAsTouched();
}

export function getEnumKeys(enumName: any) {
  return Object.entries(enumName).map(([key, value]) => ({
    name: key,
    value: value,
  }));
}

export function removeSpaceCharacters(value: any) {
  return value.replace(/\s/g, '');
}

export function formatAmount(input: any) {
  if (!input) return '';
  const amount = input.toString().replace(/([\,])/g, '.');
  return parseFloat(amount);
}

export function reformatIban(iban: any) {
  return iban
    .replace(/(.{4})/g, '$1 ')
    .trim()
    .toUpperCase();
}

export function addDays(days: number, startDate?: Date): Date {
  const date = startDate ? new Date(startDate) : new Date();
  date.setDate(date.getDate() + days);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function filterList(list: any, value: any, field: string): any {
  if (!value) return list;
  const filterValue = value.name || value?.toLowerCase();
  return filterValue.length === 0
    ? list
    : list.filter((item: any) => {
        return item[field].toLowerCase().includes(filterValue);
      });
}

export function setDefaultOnceExecutionMinDate() {
  return addDays(1);
}

export function setDefaultFirstExecutionMinDate() {
  return addDays(1);
}

export function setDefaultLastExecutionMinDate(minDateFirstExe: Date) {
  return addDays(1, minDateFirstExe);
}

export function convertShortLocalDate(date: Date): string {
  if (!date) return '';
  return formatDate(date, SHORT_DATE_FORMAT, LANGUAGE_CODE_EN);
}
