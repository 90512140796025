<h1>
  {{
    (isOperationConfirmed()
      ? operationConfirmedTitleI18nKey
      : operationPendingTitleI18nKey
    ) | translate
  }}
</h1>
<p>
  {{
    (isOperationConfirmed()
      ? operationConfirmedTextI18nKey
      : operationPendingTextI18nKey
    ) | translate
  }}
</p>
<ng-container *ngIf="isOperationConfirmed(); else loadingIndicator">
  <img alt="Confirmed" src="../../../../../assets/images/icons/confirm.svg" />
</ng-container>
<ng-template #loadingIndicator>
  <wmp-loading-indicator></wmp-loading-indicator>
</ng-template>
<button aria-label="Cancel" class="cancel" (click)="cancel()"></button>
<button
  class="primary"
  (click)="confirm()"
  *ngIf="paymentOperation !== PaymentOperation.DELETE_STANDING_ORDER"
  [disabled]="!isOperationConfirmed()"
>
  {{ 'general.button.continue' | translate }}
</button>
