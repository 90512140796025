import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentAdapterService } from '@core/services/document/document-adapter.service';
import {
  AccountTransactionModel,
  AccountUserProlongation,
  PendingPaymentsModel,
  PrintStatusType,
  ProductSummaryModel,
  PurposeCodesModel,
  TransactionsActionParams,
  convertAccountOverviewDto,
  convertCashTransactionDto,
  convertPendingPaymentDto,
  convertPurposeCodeDto,
} from '@models/account.model';
import {
  AccountOverviewDto,
  CashAccountTransactionDto,
  CashAccountTransactionFilterOptionsDto,
  CashAccountTransactionView,
  PaymentOrderDto,
} from '@models/account.model.new';
import { Store } from '@ngrx/store';
import { AccountActions, AccountStore } from '@store/account-store';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, delay, map, repeat } from 'rxjs/operators';
import { FeatureService } from '../feature.service';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private adapter: DocumentAdapterService,
    private store$: Store<AccountStore.State>,
    private featureService: FeatureService
  ) {}

  getProductSummaryData(): Observable<AccountOverviewDto> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_LIST_AND_BALANCES'
      )
    ) {
      return this.http.get<AccountOverviewDto>('/api/v1-legacy/accounts');
    } else {
      return this.http
        .get<ProductSummaryModel>('/public/api/v2/productsummary')
        .pipe(map(convertAccountOverviewDto));
    }
  }

  getAccountTransactions(payload: any): Observable<CashAccountTransactionView> {
    const cashAccountObj = of(<CashAccountTransactionDto[]>{});
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_TRANSACTIONS'
      )
    ) {
      const params = this.createFilterParams(payload);
      return forkJoin({
        pendingPayments:
          payload.page == 0
            ? this.getAccountTransactionsPendingPayments(payload)
            : cashAccountObj,
        transactions: this.http.get<CashAccountTransactionDto[]>(
          `/api/v1-legacy/accounts/${payload.accountNo}/transactions${params}`
        ),
      });
    } else {
      const params = this.createFilterParamsOldBE(payload);
      return forkJoin({
        pendingPayments: cashAccountObj,
        transactions: this.http
          .get<AccountTransactionModel[]>(
            `/public/api/v2/transactions${params}`
          )
          .pipe(map(convertCashTransactionDto)),
      });
    }
  }

  getAccountTransactionsPendingPayments(
    payload: any
  ): Observable<CashAccountTransactionDto[]> {
    const params = this.createFilterParams(payload);
    return this.http
      .get<PendingPaymentsModel[]>(
        `/public/api/wmp/v1/transactions/${payload.accountNo}/pending-payments${params}`
      )
      .pipe(map(convertPendingPaymentDto));
  }

  getPurposeCodes(
    accountNo: any
  ): Observable<CashAccountTransactionFilterOptionsDto> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_TRANSACTIONS'
      )
    ) {
      return this.http.get<CashAccountTransactionFilterOptionsDto>(
        `/api/v1-legacy/accounts/${accountNo}/transaction-filter-options`
      );
    } else {
      return this.http
        .get<PurposeCodesModel[]>(
          `/public/api/v2/transactions/${accountNo}/purpose-codes`
        )
        .pipe(map(convertPurposeCodeDto));
    }
  }

  exportAccountTransactions(payload: any): Observable<any> {
    let params = '';
    if (payload.timePeriodFrom) {
      const parameter = `timePeriodFrom=${payload.timePeriodFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.timePeriodTo) {
      const parameter = `timePeriodTo=${payload.timePeriodTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get(
      `/public/api/v2/account-transaction-export/${payload.accountId}${params}`,
      {
        responseType: 'text',
      }
    );
  }

  getSpecifications(accountId: string): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/productsummary/${accountId}/specifications`
    );
  }

  setUserTimeDespositProlongation(
    userProlongation: AccountUserProlongation
  ): Observable<any> {
    return this.http.put<any>(
      `/public/api/v2/productsummary/set-user-prolongation/${userProlongation.accountId}`,
      { userProlongation: userProlongation.userProlongation }
    );
  }

  getPositions(payload: any): Observable<any> {
    let params = '';
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountFrom) {
      const parameter = `fromAmount=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `toAmount=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get<any>(
      `/public/api/v2/productsummary/${payload.accountId}/positions${params}`
    );
  }

  getPaymentAccounts(): Observable<any> {
    return this.http.get<any>('/public/api/v2/productsummary/payment-accounts');
  }

  private createFilterParams(payload: any): string {
    let params = '';

    if (payload.amountFrom) {
      const parameter = `amount-from=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amount-to=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `date-from=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `date-to=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.page || payload.page == 0) {
      const parameter = `page=${payload.page}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `transaction-purpose-codes=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  private createFilterParamsOldBE(payload: any): string {
    let params = '';

    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.accountNo) {
      const parameter = `arrangementId=${payload.accountNo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountFrom) {
      const parameter = `amountFrom=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amountTo=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `dateFrom=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `dateTo=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.page) {
      const parameter = `from=${payload.page}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `purposeCode=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  printDocument(params: any): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/documents/print-account-transaction`,
      params
    );
  }

  printPendingDocument(params: any): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/documents/print-pending-payment`,
      params
    );
  }

  getPrintedPdfState(param: any): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/documents/get-printed-pdf-document-state/${param}`
    );
  }

  getPrintedPdf(
    param: any,
    headers: HttpHeaders = new HttpHeaders({})
  ): Observable<any> {
    const uri = `/public/api/v2/documents/get-printed-pdf-document/${param}`;
    return this.http.request<any>('get', uri, {
      headers,
      observe: 'response',
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  pollPrintStatusResponse(serviceResult: Observable<any>): void {
    this.store$.dispatch(new AccountActions.TransactionPrintStatus());
    const subscription = serviceResult
      .pipe(
        delay(1000),
        catchError((error) => {
          subscription.unsubscribe();
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusFailure(error)
          );
          return throwError(error);
        }),
        repeat()
      )
      .subscribe((resp: TransactionsActionParams) => {
        if (resp.documentState === PrintStatusType.PRINT_STATE_PROCESSED) {
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusSuccess(resp)
          );
          subscription.unsubscribe();
        }
      });
  }
}
