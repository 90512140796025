import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { PaymentActions, PaymentActionTypes } from '@store/payment-store/index';
import { PaymentService } from '@core/services/payment/payment.service';
import {
  AccountInfoResponse,
  ATTORNEY_CODE,
  CountryResponse,
  CurrencyResponse,
  PaymentApprovalModel,
  PaymentOperation,
  StandingOrdersModel,
} from '@models/payment.model';
import { PaymentSecSignSessionType } from '@models/auth-session.model';
import { PaymentUtilService } from '@modules/payments/payments.service';

@Injectable()
export class PaymentEffects {
  loadCurrencies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.LOAD_CURRENCIES_REQUEST),
      mergeMap(() =>
        this.paymentService.getCurrencies().pipe(
          map((elements: CurrencyResponse) => {
            const sortedCurrencies = this.getSortedCurrencies(elements);
            return new PaymentActions.LoadCurrenciesSuccessAction(
              sortedCurrencies
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  private getSortedCurrencies(elements: CurrencyResponse) {
    return elements.currencies.sort((current: any, next: any) =>
      current.code < next.code ? -1 : 1
    );
  }

  loadCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.LOAD_COUNTRIES_REQUEST),
      mergeMap(() =>
        this.paymentService.getCountries().pipe(
          map((elements: CountryResponse) => {
            const sortedCountries = this.getSortedCountries(elements);
            return new PaymentActions.LoadCountriesSuccessAction(
              sortedCountries
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  private getSortedCountries(elements: CountryResponse) {
    return elements.countries.sort((current: any, next: any) =>
      current.name < next.name ? -1 : 1
    );
  }

  validateAccountInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.VALIDATE_ACCOUNT_INFO_REQUEST),
      mergeMap((action: PaymentActions.ValidateAccountInfoRequestAction) =>
        this.paymentService.validateAccountInfo(action.payload).pipe(
          map(
            (elements: AccountInfoResponse) =>
              new PaymentActions.ValidateAccountInfoSuccessAction(elements)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  createPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.GET_SEC_SIGN_ID_FOR_NEW_PAYMENT_REQUEST),
      mergeMap(
        (action: PaymentActions.GetSecSignIdForNewPaymentRequestAction) =>
          this.paymentService
            .getSecSignIdForNewPayment(
              action.payload,
              action.saveTemplate,
              action.paymentToBeApproved
            )
            .pipe(
              map((response) => {
                const paymentOperation = action.paymentToBeApproved
                  ? PaymentOperation.APPROVE_PAYMENT
                  : PaymentOperation.NEW_PAYMENT;
                return this.processPaymentResult(response, paymentOperation);
              }),
              catchError((error) => {
                this.store$.dispatch(
                  new PaymentActions.LoadFailureAction({
                    error: error.message ? error.message : error,
                  })
                );
                return throwError(error);
              })
            )
      )
    );
  });

  getFees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.GET_FEES_REQUEST),
      mergeMap((action: PaymentActions.GetFeesRequestAction) =>
        this.paymentService.getFees(action.payload).pipe(
          map((elements) => new PaymentActions.GetFeesSuccessAction(elements)),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  updateStandingOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.UPDATE_STANDING_ORDER),
      mergeMap((action: PaymentActions.UpdateStandingOrderRequestAction) =>
        this.paymentService
          .updateStandingOrder(
            action.payload,
            action.saveTemplate,
            action.orderId
          )
          .pipe(
            map((response) => {
              return this.processPaymentResult(
                response,
                PaymentOperation.CHANGE_STANDING_ORDER
              );
            }),
            catchError((error) => {
              this.store$.dispatch(
                new PaymentActions.LoadFailureAction({
                  error: error.message ? error.message : error,
                })
              );
              return throwError(error);
            })
          )
      )
    );
  });

  deleteStandingOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.DELETE_STANDING_ORDER),
      mergeMap((action: PaymentActions.DeleteStandingOrderRequestAction) =>
        this.paymentService.deleteStandingOrder(action.orderId).pipe(
          map((response) => {
            return this.processPaymentResult(
              response,
              PaymentOperation.DELETE_STANDING_ORDER
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  private processPaymentResult(
    response: any,
    paymentOperation: PaymentOperation
  ) {
    if (response.status === PaymentSecSignSessionType.REJECTED) {
      this.store$.dispatch(
        new PaymentActions.SetSecSignStatusRejectedReason(response)
      );
    } else {
      this.store$.dispatch(
        new PaymentActions.SetSecSignStatus(response.status)
      );
    }
    if (response.status === PaymentSecSignSessionType.ACCEPTED) {
      sessionStorage.setItem('auth_session', response.id);
      const paymentForApproval =
        paymentOperation === PaymentOperation.NEW_PAYMENT &&
        sessionStorage.getItem('attorneyCode') === ATTORNEY_CODE.JOINTPOA;
      const serviceResult = this.paymentService.getSecSignState(
        response.id,
        paymentForApproval
      );
      this.paymentUtilService.proceedOperation(serviceResult, paymentOperation);
      return new PaymentActions.ProcessingPaymentSuccessAction(response);
    } else {
      return new PaymentActions.LoadFailureAction(response);
    }
  }

  loadStandingOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.LOAD_STANDING_ORDERS_REQUEST),
      mergeMap((action: PaymentActions.LoadStandingOrdersRequestAction) =>
        this.paymentService.getStandingOrders(action.payload).pipe(
          map(
            (elements: StandingOrdersModel[]) =>
              new PaymentActions.LoadStandingOrdersSuccessAction(elements)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadPaymentApproval$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.LOAD_PAYMENT_APPROVALS_REQUEST),
      mergeMap((action: PaymentActions.LoadPaymentApprovalsRequestAction) =>
        this.paymentService.getPaymentApprovals(action.payload).pipe(
          map(
            (elements: PaymentApprovalModel[]) =>
              new PaymentActions.LoadPaymentApprovalsSuccessAction(elements)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  declinePaymentApproval$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.DECLINE_PAYMENT_APPROVAL_REQUEST),
      mergeMap((action: PaymentActions.DeclinePaymentApprovalRequestAction) =>
        this.paymentService
          .declinePaymentApproval(action.accountId, action.orderId)
          .pipe(
            map(
              (response: any) =>
                new PaymentActions.DeclinePaymentApprovalSuccessAction(response)
            ),
            catchError((error) => {
              this.store$.dispatch(
                new PaymentActions.LoadFailureAction({
                  error: error.message ? error.message : error,
                })
              );
              return throwError(error);
            })
          )
      )
    );
  });

  getAttorneyCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.GET_PAYMENT_ATTORNEY_CODE_REQUEST),
      mergeMap((action: PaymentActions.GetPaymentAttorneyCodeRequestAction) =>
        this.paymentService.getPaymentAttorneyCode(action.accountId).pipe(
          map(
            (response: string) =>
              new PaymentActions.GetPaymentAttorneyCodeSuccessAction(response)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new PaymentActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private store$: Store,
    private actions$: Actions,
    private paymentUtilService: PaymentUtilService,
    private paymentService: PaymentService
  ) {}
}
