import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountDto,
  PaymentOrderStatus,
  PaymentValidationDto,
} from '@models/account.model.new';
import {
  AccountInfoResponse,
  CountryResponse,
  CurrencyResponse,
} from '@models/payment.model';
import {
  PaymentOrderRequestDto,
  PaymentOrderRequestParams,
} from '@models/payment.model.new';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private _paymentData$ = new BehaviorSubject<PaymentOrderRequestDto>(
    <PaymentOrderRequestDto>{}
  );
  private _accountData$ = new BehaviorSubject<AccountDto>(<AccountDto>{});

  constructor(private http: HttpClient) {}

  get paymentData$(): Observable<PaymentOrderRequestDto> {
    return this._paymentData$.asObservable();
  }
  get paymentDataObj(): PaymentOrderRequestDto {
    return this._paymentData$.getValue();
  }

  updatePaymentData(paymentData: PaymentOrderRequestDto): void {
    this._paymentData$.next(paymentData);
  }

  get accountData$(): Observable<AccountDto> {
    return this._accountData$.asObservable();
  }
  get accountDataObj(): AccountDto {
    return this._accountData$.getValue();
  }

  updateAccountData(accountData: AccountDto): void {
    this._accountData$.next(accountData);
  }

  getCurrencies(): Observable<CurrencyResponse> {
    return this.http.get<any>('/public/api/v2/currencies');
  }

  getCountries(): Observable<CountryResponse> {
    return this.http.get<any>('/public/api/v2/countries');
  }

  validateAccountInfo(accountInfo: any): Observable<AccountInfoResponse> {
    const queryParams = new HttpParams({ fromObject: accountInfo });
    return this.http.get<any>('/public/api/v2/validate-account-info', {
      params: queryParams,
    });
  }

  validatePaymentOrder(
    paymentData: PaymentOrderRequestDto
  ): Observable<PaymentValidationDto> {
    return this.http.post<PaymentValidationDto>(
      '/api/v1/payment-orders/validate',
      paymentData,
      { headers: { 'Content-Type': 'application/json;charset=utf-8' } }
    );
  }

  sendPaymentOrder(
    paymentData: PaymentOrderRequestDto,
    params: PaymentOrderRequestParams
  ): Observable<string> {
    return this.http.post<any>(
      `/api/v1/payment-orders?create-template=${params.createTemplate}&mobile=${params.mobile}`,
      paymentData
    );
  }

  getPaymentOrderStatus(
    paymentOrderId: string
  ): Observable<PaymentOrderStatus> {
    return this.http.get<PaymentOrderStatus>(
      `/api/v1/payment-orders/${paymentOrderId}/status`
    );
  }

  deletePaymentOrder2fa(
    paymentOrderId: string
  ): Observable<PaymentOrderStatus> {
    return this.http.delete<PaymentOrderStatus>(
      `/api/v1/payment-orders/${paymentOrderId}/current-2fa`
    );
  }

  getSecSignIdForNewPayment(
    payload: any,
    saveTemplate = false,
    paymentToBeApproved = false
  ): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/payment-orders?template=${saveTemplate}&paymentToBeApproved=${paymentToBeApproved}`,
      payload
    );
  }

  getFees(payload: any): Observable<any> {
    return this.http.post<any>(`/public/api/v2/payment-orders/fees`, payload);
  }

  getSecSignState(
    sessionId: string,
    paymentToBeApproved = false
  ): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/payment-order-state?id=${sessionId}&paymentForApproval=${paymentToBeApproved}`
    );
  }

  getStandingOrders(accountId: string) {
    return this.http.get<any>(
      `/public/api/v2/payment-orders/account/${accountId}`
    );
  }

  updateStandingOrder(
    standingOrder: any,
    saveTemplate: boolean,
    orderId: string
  ): Observable<any> {
    return this.http.put<any>(
      `/public/api/v2/payment-orders/${orderId}?template=${saveTemplate}`,
      standingOrder
    );
  }

  deleteStandingOrder(paymentOrderId: string): Observable<boolean> {
    return this.http.delete<any>(
      `/public/api/v2/payment-orders/${paymentOrderId}`
    );
  }

  getPaymentApprovals(accountId: string) {
    return this.http.get<any>(
      `/public/api/v2/payment-approval-orders/${accountId}`
    );
  }

  declinePaymentApproval(accountId: string, orderId: string) {
    return this.http.put<any>(
      `/public/api/v2/payment-approval-orders/${orderId}?accountId=${accountId}`,
      {}
    );
  }

  getPaymentAttorneyCode(accountId: string) {
    return this.http.get<any>(`/public/api/v2/attorneycode/${accountId}`, {
      responseType: 'text' as 'json',
    });
  }
}
