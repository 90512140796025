<div class="logo">
  <img
    id="berenbergLogo"
    alt="logoBlack"
    src="../../../assets/images/berenberg_logo_black.png"
  />
  <hr />
</div>
<div class="menu-container">
  <div class="menu">
    <div
      class="portfolio-selector"
      *ngIf="allMasterNumbers && isAClientSelected()"
    >
      <form>
        <div
          id="masterNumberSelector"
          class="trigger"
          [ngStyle]="{
            cursor: allMasterNumbers.length > 1 ? 'cursor' : 'default'
          }"
          (click)="conjureMasterNumberSelectorDropdown()"
          #masterNumberSelectorTrigger
        >
          <p id="selectedMasterNumberName">
            {{ selectedMasterNumber?.name }}
            <img
              id="masterNumberButton"
              *ngIf="allMasterNumbers.length > 1"
              src="../../../assets/images/icons/chevron-down.svg"
            />
          </p>
          <p id="selectedMasterNumber">
            {{ 'navigationHeader.rootNumber' | translate }}
            {{ selectedMasterNumber?.masterNumber }}
          </p>
        </div>
        <mat-form-field
          appearance="outline"
          class="navigation-header-portfolio-selector"
        >
          <mat-label
            >{{ selectedMasterNumber?.masterNumber }} -
            {{ selectedMasterNumber?.name }}</mat-label
          >
          <mat-select
            panelClass="navigation-header-portfolio-selector"
            disableOptionCentering
            #masterNumberSelectorDropdown
            [(value)]="selectedValue"
          >
            <mat-option
              *ngFor="let masterNumber of allUnselectedMasterNumbers"
              [value]="masterNumber.masterNumber"
            >
              <span
                [id]="
                  'allUnselectedMasterNumbers_' +
                  getMasterNumberId(allUnselectedMasterNumbers, masterNumber)
                "
                >{{ masterNumber.masterNumber }} - {{ masterNumber.name }}</span
              >
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="language-selector">
      <wmp-switch-language></wmp-switch-language>
    </div>
    <div class="logout">
      <button id="logout" class="tertiary" (click)="logout()">
        <i
          ><img
            alt="logout"
            src="../../../assets/images/icons/logout-variant.svg"
        /></i>
        <span>{{ 'logout' | translate }}</span>
      </button>
    </div>
  </div>
  <div class="info">
    <h1 id="pageTitle">{{ pageTitle | translate }}</h1>
    <ng-container *ngIf="isWealthRoute && isAClientSelected()">
      <wmp-security-value-date></wmp-security-value-date>
    </ng-container>
  </div>
</div>
