import { Injectable } from '@angular/core';
import { Observable, noop } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginResultModel, StatusData } from '@models/login.model';
import {
  LoginRequestModel,
  LoginResultModel as LRModel,
  OnboardingResponse,
  UserSessionResponse,
} from '@models/authentication/authentication.model';
import { Router } from '@angular/router';
import { KEY_SECSIGN_SESSION, KEY_USER_LOGGED_IN } from '@core/constants/keys';
import { CookieService } from 'ngx-cookie-service';
import { Environment } from '@models/auth-session.model';
import { concatMap } from 'rxjs/operators';
import { PATH_AUTH, PATH_LOGIN } from '@core/constants/path';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  REFRESH_TOKEN,
} from '@core/constants/storage';
import { AppService } from '@core/services/app.services';
import { BackendErrorService } from './backend-error.service';
import { FeatureService } from './feature.service';
import {
  transformOnboardingDto,
  transformUserSessionDto,
  transformWebLoginDto,
} from '@core/utils/transformDtos/transform-authentication-dtos';

@Injectable({ providedIn: 'root' })
export class AuthService {
  firstName = 'firstname';
  status = 'status';
  sub = 'sub';

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private appService: AppService,
    private backendErrorService: BackendErrorService,
    private featureService: FeatureService
  ) {}

  demoLogin(): Observable<UserSessionResponse> {
    return this.http.get<UserSessionResponse>(`/api/v1/auth/login/demo`);
  }

  loginWithUsernameAndPassword(
    userName: string,
    password: string
  ): Observable<LRModel> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      const body: LoginRequestModel = {
        login: userName,
        pwd: password,
      };
      return this.http.post<LRModel>(`/api/v1/auth/login/web`, body);
    } else {
      const body = {
        username: userName,
        password: password,
      };
      return this.http
        .post<LoginResultModel>(`/authentication/web-login`, body)
        .pipe(
          concatMap((resultLogin) => {
            return transformWebLoginDto(resultLogin);
          })
        );
    }
  }

  public getUserRoles(): Observable<any> {
    return this.http.get<any>('/public/api/v1/navigation/user-roles');
  }

  getUserLoggedIn(): boolean {
    return sessionStorage.getItem(KEY_USER_LOGGED_IN) == 'true';
  }

  getAuthSessionStatus(sessionId: string): Observable<LRModel> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.get<LRModel>(
        `/api/v1/auth/login/session/${encodeURIComponent(sessionId)}`
      );
    } else {
      return this.http
        .get<LoginResultModel>(`/authentication/web-login/status`)
        .pipe(
          concatMap((resultLogin) => {
            return transformWebLoginDto(resultLogin);
          })
        );
    }
  }

  getUserStatus(userName: string): Observable<any> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    return this.http.get<any>(`/api/v1/auth/login/user/${userName}/status`);
  }

  getAuthStatus(): Observable<UserSessionResponse> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.get<UserSessionResponse>(`/api/v1/auth/session/info`);
    } else {
      return this.http.get<StatusData>(`/authentication/api/auth/status`).pipe(
        concatMap((resultStatus) => {
          return transformUserSessionDto(resultStatus);
        })
      );
    }
  }

  refreshToken(
    headersOption: HttpHeaders = new HttpHeaders({})
  ): Observable<UserSessionResponse> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.post<UserSessionResponse>(
        `/api/v1/auth/session/refresh`,
        {}
      );
    } else {
      const uri = `/authentication/api/auth/refresh`;
      headersOption = new HttpHeaders().set('X-XSRF-TOKEN', this.getXSRF());

      const requestOptions = {
        headers: headersOption,
      };

      return this.http.get(uri, requestOptions).pipe(
        concatMap((resultStatus: any) => {
          return transformUserSessionDto(resultStatus);
        })
      );
    }
  }

  onboardingChangePassword(
    userName: string,
    password: string,
    oldPass: string
  ): Observable<OnboardingResponse> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      const body = {
        login: userName,
        oldPwd: oldPass,
        newPwd: password,
      };
      return this.http.post<OnboardingResponse>(
        `/api/v1/auth/login/onboarding`,
        body
      );
    } else {
      const body = {
        username: userName,
        newpwd: password,
        oldpwd: oldPass,
      };
      return this.http
        .post<LoginResultModel>(`/authentication/web-login/onboarding/`, body)
        .pipe(
          concatMap((resultOnboardingStatus: any) => {
            return transformOnboardingDto(resultOnboardingStatus);
          })
        );
    }
  }

  getOnboardingStatus(userName: string): Observable<OnboardingResponse> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.get<OnboardingResponse>(
        `/api/v1/auth/login/onboarding/${userName}`
      );
    } else {
      return this.http
        .get<LoginResultModel>(
          `/authentication/web-login/onboarding/status/` + userName
        )
        .pipe(
          concatMap((resultOnboardingStatus: any) => {
            return transformOnboardingDto(resultOnboardingStatus);
          })
        );
    }
  }

  deleteToken(sessionId: string): Observable<any> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.get<OnboardingResponse>(
        `/api/v1/auth/login/session/${encodeURIComponent(sessionId)}`
      );
    } else {
      return this.http.delete<any>(`/authentication/api/auth/token`);
    }
  }

  logout(): void {
    this.deleteToken(this.getSecSignSession()).subscribe(
      (response) => {
        this.deleteCookies();

        this.router
          .navigate([PATH_AUTH + PATH_LOGIN], {})
          .then(() => window.location.reload());
      },
      (error) => {
        this.router
          .navigate([PATH_AUTH + PATH_LOGIN], {})
          .then(() => window.location.reload());
      }
    );

    this.setSecSignSession('');
    sessionStorage.setItem(KEY_USER_LOGGED_IN, 'false');
    this.appService.setUserLoggedIn(false);
    this.removeTokenFromSessionStorage();
  }

  deleteStorageCookies(): void {
    this.deleteToken(this.getSecSignSession()).subscribe(
      (response) => {},
      (error) => {
        this.backendErrorService.badRequestCatch.next(false);
      }
    );
    localStorage.clear();
    sessionStorage.setItem(KEY_USER_LOGGED_IN, 'false');
    this.appService.setUserLoggedIn(false);
    this.removeTokenFromSessionStorage();
    this.deleteCookies();
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  getSecSignSession(): string {
    const retValue = sessionStorage.getItem(KEY_SECSIGN_SESSION);
    return retValue ? retValue : '';
  }

  setSecSignSession(sessionValue: string): void {
    sessionStorage.setItem(KEY_SECSIGN_SESSION, sessionValue);
  }

  saveTokenToSessionStorage(): void {
    const authorization = this.cookieService.get(AUTHORIZATION);
    sessionStorage.setItem(ACCESS_TOKEN, authorization);
  }

  removeTokenFromSessionStorage(): void {
    sessionStorage.removeItem(ACCESS_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
  }

  public setSecSignSessionStateCookie(): void {
    const authSession = this.cookieService.get('secsign-session-state');
    sessionStorage.setItem('auth_session', authSession);
  }

  getXSRF() {
    return this.cookieService.get('XSRF-TOKEN');
  }

  deleteCookies() {
    this.cookieService.deleteAll('/');
  }

  redirectToLogin(err: any) {
    if (
      [401].includes(err.status) &&
      sessionStorage.getItem(KEY_USER_LOGGED_IN) === 'true'
    ) {
      // auto logout if 401 response returned from api
      if (this.isRmp()) {
        this.router
          .navigate(['/rmp'])
          ?.then()
          .catch(() => noop());
      } else {
        this.logout();
      }
    } else if ([401].includes(err.status)) {
      if (this.isRmp()) {
        this.router
          .navigate(['/authentication/rmp/start-sso'])
          ?.then()
          .catch(() => noop());
      } else {
        this.router
          .navigate([PATH_AUTH + PATH_LOGIN], {})
          .then()
          .catch(() => noop());
      }
    }
    this.backendErrorService.badRequestCatch.next(false);
  }
}
